.about-wrapper {
  background: #0a0203;
}

.about-text-wrapper {
  padding: 6rem 3rem;
}

.about-title {
  position: relative;
  color: #d4af37;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 600;
}

.about-text {
  font-size: 16px;
  color: white;
}

.about-images {
  width: 50%;
  background-image: url("../../../assets/images/About/about.jpg");
  background-size: cover;
  background-position: center;
}

.about-diplom {
  width: 136px;
  filter: grayscale(1);
  transition: 0.5s;
  height: fit-content;

  &:hover {
    transition: 0.5;
    cursor: pointer;
    filter: grayscale(0);
    box-shadow: 2px 4px 6px black;
    transform: scale(1.5);
    z-index: 999;
  }
}

.slider {
  position: fixed;
  z-index: 999999;
  top: 0;
  background: rgb(0 0 0 / 89%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-diplom-slide {
  max-width: 100%;
  padding: 1rem;
  max-height: 80%;
}

.control {
  position: absolute;
  bottom: 2rem;
  color: white;
  display: flex;
  gap: 1rem;
}

.close-slider {
  color: white;
  position: absolute;
  top: 2rem;
}

.download-button {
  background: #d4af37;
  color: black;
  padding: 1rem;
  font-size: 24px;
  font-weight: 700;
  text-decoration: none;
  width: 45%;
  position: relative;
  display: block;
  text-align: center;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    cursor: pointer;
    opacity: 0.8;
  }
}

.about-input {
  background-color: #ffffff;
  background-image: none;
  border: 2px solid #d4af37;
  color: #000000;
  font-family: "Montserrat";
  font-size: 23px;
  font-weight: 500;
  padding: 4px 4px 4px 4px;
  width: 25rem;
}

.about-textarea {
  background-color: #ffffff;
  background-image: none;
  border: 2px solid #d4af37;
  color: #000000;
  font-family: "Montserrat";
  font-size: 23px;
  font-weight: 500;
  padding: 4px 4px 4px 4px;
  width: 25rem;
}

.about-checkboxs {
  width: 25rem;
}

.button-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.button-about {
  color: #d4af37;
  font-weight: 700;
  background-image: url("../../../assets/images/About/button.png");
  padding: 11px 0rem 2rem;
  width: 14rem;
  background-size: contain;
  text-align: center;
  background-repeat: no-repeat;

  &:hover {
    cursor: pointer;
  }
}

label {
  font-size: 15px;
}

.alert-about {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 1rem;
}

@media screen and (max-width: 991px) {
  .about-images {
    display: none;
  }

  .about-text-wrapper {
    width: 100% !important;
  }

  .about-download {
    display: grid !important;
    gap: 1rem;
  }

  .download-button {
    width: 100%;
  }

  .about-input-wrapper {
    display: block !important;
  }

  .about-input {
    width: 100%;
  }

  .about-textarea {
    width: 100%;
    margin-top: 1rem;
  }

  .about-checkboxs {
    width: 100%;
  }
}
