@import "../../../style/color";
@import "../../../style/font";

.hello-images {
  position: relative;
  padding: 11rem 0;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: left !important;
}

.hello-images-title {
  color: $yellow;
  font-family: $montserrat;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  position: relative;
  text-align: center;
  margin: 0 auto;
}

.hello-images-hr {
  width: 4rem;
  height: 1px;
  background: $yellow;
  margin: 0 auto;
  margin-top: 2rem;
}
