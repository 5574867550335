.title {
  color: #000000;
  font-family: Montserrat;
  font-size: 19px;
  font-weight: 600;
  height: 3.5rem;
}

.items {
  background: #d4af37;
  color: #000000;
  font-family: Arial;
  font-weight: 700;
  text-align: center;
  padding: 1rem;
  margin: 1rem;
  height: 5rem;
  transition: 0.5s;
  display: grid;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 15px;

  &:hover {
    transition: 0.5s;
    cursor: pointer;
    background: #dac271;
    box-shadow: 0px 0px 2px 5px #d4af37;
  }
}

.send-to-amo {
  text-align: center;
  padding: 2rem;
}

.btn-send-amo {
  background: #d4af37;
  text-decoration: none;
  padding: 1rem;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  color: white;

  &:hover {
    transition: 0.5s;
    cursor: pointer;
    background: #dac271;
    box-shadow: 0px 0px 2px 5px #d4af37;
  }
}

.item-wrap {
  width: 23rem;
}

@media screen and (max-width: 991px) {
  .d-flex-service {
    display: grid !important;
  }

  .title {
    font-size: 15px;
  }

  .item-wrap {
    width: 100%;
  }

  .items {
    font-size: 14px;
  }
}
