@import "../../../style/color";
@import "../../../style/font";

header {
  background: $yellow;
  height: 95px;
  position: relative;
  z-index: 10;
}

.header-wrapper {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.wrap-header {
  height: 95px;
  width: 40rem;
}

.header-logo {
  width: 260px;
}

.header-city {
  color: $black;
  font-family: $montserrat;
  font-size: 19px;
  font-weight: 300;
  line-height: 20px;
}

.header-tel {
  color: $black;
  font-family: $roboto;
  font-size: 24px;
  font-weight: 900;
  text-decoration: none;
  transition: 0.5s;
}

.header-time {
  color: $black;
  font-family: $roboto;
  font-size: 19px;
  font-weight: 300;
}

.header-lang {
  color: $black;
  font-family: $roboto;
  font-size: 19px;
  font-weight: 900;
  transition: 0.5s;
}

.header-hr {
  width: 1px;
  height: 50px;
  background: $black;
}

.header-social {
  transition: 0.5s;
  margin-bottom: 5px;
}

.header-burger {
  width: 3rem;
  margin-left: auto;
  height: 95px;
  align-items: center;
  display: none;
}

.header-hovered:hover {
  cursor: pointer;
  transition: 0.5s;
  filter: invert(1);
  transform: scale(1.1);
}

.item-nav {
  color: $yellow;
  text-decoration: none;
  margin-right: 4rem;
  font-size: 20px;
  padding-bottom: 1rem;
}

.item-nav.active {
  border-bottom: 1px solid;
}

.header-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.logo {
  width: 275px;
}

.header-lang.active {
  color: white;
}

@media screen and (max-width: 1199px) {
  .header-container {
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .wrap-header {
    width: 50%;
  }

  .d-none-991 {
    display: none !important;
  }
}

@media screen and (max-width: 940px) {
  .item-nav {
    margin-right: auto;
    margin-left: auto;
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .header-wrapper {
    justify-content: flex-start;
    align-items: center;
  }

  .wrap-header {
    display: none !important;
  }

  .header-burger {
    display: flex;
  }

  .header-logo {
    width: auto;
    height: 85px;
  }

  .item-nav {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .logo {
    width: 200px;
  }
}
