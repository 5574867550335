.area {
  background: #d4af37;
}

.area-title {
  font-size: 25px;
  text-align: center;
}

.area-item {
  display: grid;
  width: 360px;
  gap: 20px;
  transition: 0.5s;
  padding-bottom: 2rem;
  color: black;
  text-decoration: none;

  &:hover {
    transition: 0.5s;
    cursor: pointer;
    opacity: 0.8;
  }
}

.about-image {
  width: 24%;
}

@media screen and (max-width: 1199px) {
  .area-wrapper {
    justify-content: space-evenly !important;
  }

  .area-images {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .about-image {
    width: 49%;
  }
}

@media screen and (max-width: 768px) {
  .about-image {
    width: 100%;
  }
}
