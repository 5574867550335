@import "../../../style/color";
@import "../../../style/font";

.contact-images {
  position: relative;
  padding: 7rem 0;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.contact-panel {
  background: rgba($yellow, 0.6);
  padding: 1rem 3rem 3rem;
  width: 80%;
  margin: 0 auto;

  h1 {
    color: $black;
    font-family: $montserrat;
    font-size: 37px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 2rem;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 420px;

    .contact-input {
      background-color: $white;
      border: 2px solid $yellow;
      color: $black;
      font-family: montserrat;
      font-size: 24px;
      font-weight: 300;
      height: 58px;
      margin-bottom: 0.5rem;
    }

    .contact-textarea {
      background-color: $white;
      border: 2px solid $yellow;
      color: $black;
      font-family: montserrat;
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 0.5rem;
    }
  }

  .contact-info {
    display: grid;
    align-items: center;
    width: 50%;

    .icon-contact {
      text-align: center;
      padding: 5px 0;
    }

    .contact-info-text {
      color: $black;
      font-family: $montserrat;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }
  }
}

.button-panel {
  position: relative;
  width: 80%;
  margin: 0 auto;
  text-align: right;

  button {
    border: none;
    color: white;
    background: url("../../../assets/images/Contact/button.png");
    width: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 102px;
    font-size: 25px;
    display: inline-flex;
    align-items: flex-start;
    justify-content: space-around;
    line-height: 4rem;
  }
}

@media screen and (max-width: 1399px) {
  .contact-panel {
    width: 93%;
  }
  .button-panel {
    width: 93%;
  }
}

@media screen and (max-width: 1199px) {
  .contact-panel {
    width: 100%;
    padding: 1rem;

    .contact-info {
      display: grid;
      align-items: center;
      width: 56%;
    }

    .form {
      width: 350px;
    }
  }
  .button-panel {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 991px) {
  .container-response {
    max-width: 100% !important;
    padding: 0;
  }

  .flex-response {
    display: flex;
    flex-direction: column;
  }

  .container-response {
    max-width: 100% !important;
    padding: 0;
  }

  .contact-panel {
    .form {
      width: 100%;
    }

    .contact-info {
      width: 100%;
      text-align: center;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-panel h1 {
    font-size: 30px;
  }
}
