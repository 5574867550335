.slider-button {
  position: absolute;
  top: 50%;
  background: none;
  border: none;
}

.back-slider {
  left: 1rem;
}

.next-slider {
  right: 1rem;
}

.home-about {
  background: url("../../../assets/images/Main/aboutlayer.jpg");
  height: 44rem;
  background-position: 85%;
}

.home-about-text {
  margin-left: auto;
  display: grid;
  padding: 8rem 5rem;
  align-items: center;
  height: 100%;
  width: 65rem;
}

.number-block {
  text-align: center;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    transform: scale(1.2);
    cursor: pointer;
    filter: drop-shadow(2px 4px 6px #645318);
  }
}

.number-block-wrapper {
  background: #d4af37;
  color: white;
}

.numbers {
  font-size: 6rem;
  font-weight: 900;
}

.items-number {
  text-align: center;
  padding: 1rem;
}

.plus {
  font-size: 4rem;
  font-weight: 900;
}

.item-number-text {
  font-size: 1.52rem;
  font-weight: 500;
}

.number-wrapper {
  align-items: baseline;
  justify-content: center;
  line-height: 4rem;
}

.number-block-inset {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.main-button-about {
  color: #d4af37;
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  border: 3px solid;
  width: 12rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    cursor: pointer;
    background: #d4af37;
    color: white;
  }
}

.wrap_project_view {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  justify-content: center;
}

.item_project {
  width: 18.9rem;
  height: 30rem;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  transition: 0.5s;
  margin-left: 1rem;
  margin-top: 1rem;
  background-position: center;
}

.begin_project {
  color: black;
  font-size: 1.1rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  height: 10rem;
  align-items: center;
  padding: 1rem;
  justify-content: center;
}

.second_project {
  margin: 0;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  color: black;
}

.item1 {
  background-image: url("../../../assets/images/Main/layers/Layer 001 (2).jpg");
}

.item2 {
  background-image: url("../../../assets/images/Main/layers/Layer 001 (8).jpg");
}

.item3 {
  background-image: url("../../../assets/images/Main/layers/Layer 001 (3).jpg");
}

.item4 {
  background-image: url("../../../assets/images/Main/layers/Layer 001 (4).jpg");
}

.item5 {
  background-image: url("../../../assets/images/Main/layers/Layer 001 (1).jpg");
}

.item6 {
  background-image: url("../../../assets/images/Main/layers/Layer 001 (7).jpg");
}

.item7 {
  background-image: url("../../../assets/images/Main/layers/Layer 001 (5).jpg");
}

.item8 {
  background-image: url("../../../assets/images/Main/layers/Layer 001 (6).jpg");
}

.item_project:hover,
.item_project_big:hover {
  cursor: pointer;
}

.inset_project {
  display: block;
  height: 100%;
  align-items: center;
  transition: 0.5s;
  background: #d4af37;
  transform: translateY(68%);

  &:hover {
    background: #d4af37c2;
    transition: 0.5s;
    transform: translate(0);
  }
}

.btns {
  background: white;
  padding: 0.5rem;
  border-radius: 5px;
  display: block;
  width: max-content;
  margin: 0 auto;
  position: absolute;
  bottom: 0.5rem;
  left: 25%;
  right: 25%;
  color: black;
  text-decoration: none;
}

.imgme {
  width: 11rem;
  height: 8rem;
  position: relative;
  filter: grayscale(1);

  &:hover {
    cursor: pointer;
    filter: grayscale(0);
  }
}

.logo-img {
  border: 0px solid #000000;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  top: 0;
  width: 100%;
}

.main-partner {
  text-align: left;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5rem;
  justify-content: center;
}

.main-preference {
  background: #0a0203;
}

.card_index {
  text-align: center;
  width: 50%;
}

.yellow_titless {
  color: #d4af37;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.07px;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.yellow_subtitles {
  color: #d4af37;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0.07px;
  max-width: 30rem;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.hover_yellow {
  &:hover {
    cursor: pointer;
    transition: 0.5s;
    transform: scale(1.2);
  }
}

#amo1 {
  width: 100%;
  position: relative;
  background: #d5b134;
}

#amo2 {
  width: 37rem;
  margin: 0 auto;
  position: relative;
  padding: 2rem;
}

.download-form {
  text-align: center;
  position: absolute;
  z-index: 999;
  width: 360px;
  height: 280px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center;
  right: 15vw;
  top: 31vw;
  background: url("../../../assets/images/Main/form.png");
}

.download-form-text {
  color: #d4af37;
  text-align: center;
  padding: 2rem 0 1rem;
  font-size: 21px;
}

.download-form-input {
  border: 2px solid #d4af37;
  text-align: center;
  width: 100%;
  height: 3rem;
  font-size: 25px;
}

.download-form-button {
  font-variant: all-petite-caps;
  background: no-repeat;
  border: none;
  color: white;
  padding-top: 1rem;
  font-size: 35px;
}

.slide-wrapper {
  position: relative;
  height: calc(100% + 20vh);
}

@media screen and (max-width: 1399px) {
  .item-number-text {
    font-size: 19px;
    font-weight: 500;
  }
}

@media screen and (max-width: 1199px) {
  .number-block-inset {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .number-block {
    width: 50%;
  }
}

@media screen and (max-width: 1099px) {
  .download-form {
    display: none;
  }
  .home-about-text {
    width: 100%;
    padding: 3rem;
  }

  .home-about {
    height: 100%;
    background-position: right;
  }
}

@media screen and (max-width: 991px) {
  .flex-preference {
    display: grid !important;
  }

  .card_index {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .number-block {
    width: 100%;
  }

  #amo2 {
    max-width: 100%;
  }
}
