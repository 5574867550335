@import "../../../style/color";
@import "../../../style/font";

footer {
  width: 100%;
  height: 55px;
  background: $yellow;
  display: flex;
  align-items: center;
}

.copyright-text {
  color: $black;
  font-family: $montserrat;
  font-size: 16px;
  margin: 0;
}

.footer-component {
  background: $black;
  color: $yellow;
  padding: 5rem 0;
}

.navigation-item {
  color: $yellow;
  font-family: $roboto;
  font-size: 21px;
  font-weight: 300;
  text-decoration: none;
}

.navigation-item.active {
  border-bottom: 1px solid;
}

.navigation {
  display: grid;
  align-items: center;
  height: 15rem;
}

.call-me-please-title {
  color: $yellow;
  font-family: $roboto;
  font-size: 21px;
  font-weight: 300;
  text-align: center;
}

.call-me-please-image-wrapper {
  width: auto;
  padding: 2rem;
}

.call-me-please {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.call-me-please-image {
  width: 6rem;
}

.follow-me-title {
  color: $yellow;
  font-family: $roboto;
  font-size: 21px;
  font-weight: 300;
  text-align: center;
}

.follow-me-image-wrapper {
  width: auto;
  padding: 1rem;
}

.follow-me {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.follow-me-image {
  text-decoration: none;
}

.call_from {
  position: fixed;
  top: 0;
  z-index: 999999;
  background: #000000e0;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-wrapper {
  display: block;
  position: relative;
  background: white;
  border: 2px solid #d4af37;
  padding-bottom: 1rem;
}

.call {
  position: absolute !important;
  top: -5rem;
  text-align: center;
  width: 100%;
  left: 0;
}

.close-callback {
  position: absolute;
  top: -5rem;
  right: 0;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    cursor: pointer;
    color: white;
  }
}

@media screen and (max-width: 991px) {
  .footer-logo {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .follow-me {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .navigation {
    display: none;
  }

  .call-me-please {
    margin: 0 auto;
  }
}
