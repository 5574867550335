@import "./style/color";
@import "./style/font";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,500&display=swap"');

body {
  font-family: $montserrat;
}

.hover {
  transition: 0.25s;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: scale(1.1);
    transition: 0.25s;
  }
}
