.project-wrapper {
  display: flex;
  gap: 2rem;
  padding: 2rem;
}

.text-block {
  height: 370px;
  overflow: hidden;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.text-block.active {
  height: auto;
}

.slider-item {
  position: absolute;
  transition: 0.5s;
  z-index: -1;
}

.sliders {
  width: 134rem;
  display: block;
}

.images-project {
  width: 490px;
  height: 370px;
}

.hidden {
  position: absolute;
  bottom: -3px;
  background: white;
  width: 100%;
  color: gray;
  z-index: 999999;
  font-size: 12px;
}

.shadows {
  position: absolute;
  bottom: -3px;
  background: white;
  width: 100%;
  color: gray;
  z-index: 999999;
  font-size: 12px;
  box-shadow: 0px 0px 9px 9px white;
}

.title-variant {
  font-variant: all-petite-caps;
  font-size: 1.5rem;
}

.diplomas {
  text-align: center;
  padding: 2rem;
}

@media screen and (max-width: 1199px) {
  .project-wrapper {
    display: grid;
  }

  .images-project {
    width: 100%;
    height: auto;
  }
}
