.success-form {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  background: #00000096;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.success-inset {
  background: black;
  padding: 2rem;
  text-align: center;
  box-shadow: 9px 9px 2px 0 #d4af3766;
  border-radius: 4rem 0 5rem 0;
  margin: 1rem;
}

.success-text {
  color: #d4af37;
  font-size: 21px;
  font-weight: bold;
}

.success-check {
  fill: #d4af37;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  margin: 0 auto;
}
