.mobile-menu {
  position: fixed;
  z-index: 999999;
  background: #d4af37;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-navigation-item {
  color: black;
  font-size: 19px;
  font-weight: 700;
  text-decoration: none;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.mobile-navigation-item.active {
  border-bottom: 3px solid;
}

.langs {
  padding: 1rem;
  width: 17rem;
}

.mobile-navigation {
  display: grid;
  align-items: center;
  padding: 2rem;
}

.close-menu {
  position: absolute;
  z-index: 999999;
  top: 24px;
  width: 3rem;
  right: 12px;
  color: white;
}
